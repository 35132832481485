import calculateEligibilityMet from '@lib/questionnaire/trialApplication/calculateEligibilityMet'
import calculateOddsOfEligibility from '@lib/questionnaire/trialApplication/calculateOddsOfEligibility'
import type { TrialApplicationQuestionAttribute } from '@lib/questionnaire/trialApplication/generatePrescreeningQuestionConfig'
import { REUSABLE_QUESTION_ATTRIBUTES } from '@lib/questionnaire/trialApplication/generatePrescreeningQuestionConfig'
import TrialApplicationForm from '@lib/questionnaire/trialApplication/TrialApplicationForm'
import type { BasicFormDataPoint, FormData } from '@lib/types/FormData'
import pickBySelectedKeys from '@lib/utilities/object/pickBySelectedKeys'
import isEmptyString from '@lib/utilities/text/isEmptyString'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import pickBy from 'lodash/pickBy'
import type { ReactNode } from 'react'
import { createContext, useContext, useRef, useState } from 'react'

export const TRIAL_APPLICATION_STARTING_QUESTION_INDEX = -1
export const TRIAL_APPLICATION_ELIGIBILITY_STARTING_QUESTION_INDEX = 0

export type OddsOfEligibility = {
  criteriaMet: number
  label: string
  percent: number
  prevPercent: number
}

/**
 * Provide a TrialApplicationContext to be able to share trial application answers (eligibility, conditions etc) between components. Import
 * this in a `useContext`: https://reactjs.org/docs/hooks-reference.html#usecontext
 */
export const TrialApplicationQuestionnaireContext = createContext<
  | {
      getCurrentEligibilityQuestionIndex: (slug: string) => number
      getCurrentQuestionIndex: (slug: string) => number
      getOddsOfEligibility: (slug?: string | null) => OddsOfEligibility
      getTrialApplicationQuestionnaire: (slug: string) => FormData
      persistTrialApplicationQuestionnaireData: ({
        data,
        slug,
      }: {
        data: FormData
        slug: string
      }) => void
      prefillReusableValuesInTrialApplication: (slug: string) => void
      setCurrentEligibilityQuestionIndex: ({
        index,
        slug,
      }: {
        index: number
        slug: string
      }) => void
      setCurrentQuestionIndex: ({
        index,
        slug,
      }: {
        index: number
        slug: string
      }) => void
    }
  | undefined
>(undefined)

function TrialApplicationQuestionnaireProvider({
  children,
}: {
  children: ReactNode
}) {
  const formInLocalStorage = new TrialApplicationForm()
  const [trialApplicationState, setTrialApplicationState] = useState<
    Record<string, FormData | BasicFormDataPoint>
  >(formInLocalStorage.getAll())

  const [questionIndices, setQuestionIndices] = useState(
    new Map<string, number>(),
  )
  const [eligibilityQuestionIndices, setEligibilityQuestionIndices] = useState(
    new Map<string, number>(),
  )

  const persistTrialApplicationQuestionnaireData = ({
    data,
    slug,
  }: {
    data: FormData
    slug: string
  }) => {
    const updatedTrialApplication = {
      ...(trialApplicationState[slug] as FormData),
      ...data,
    }
    const cleanedTrialApplication = pickBy(
      updatedTrialApplication,
      (v) => v !== undefined && !isEmptyString(v),
    )

    // Select the entries from data that are indicated as reusable across other trials
    const reusableData = pickBySelectedKeys(
      data,
      REUSABLE_QUESTION_ATTRIBUTES,
    ) as Partial<Record<TrialApplicationQuestionAttribute, BasicFormDataPoint>>

    setTrialApplicationState({
      ...trialApplicationState,
      [slug]: cleanedTrialApplication,
      ...(reusableData && reusableData),
    })
    formInLocalStorage.set(slug, cleanedTrialApplication)

    Object.entries(reusableData).forEach(([key, value]) => {
      formInLocalStorage.set(key, value)
    })
  }

  const prefillReusableValuesInTrialApplication = (slug: string) => {
    const reusableData: Partial<
      Record<TrialApplicationQuestionAttribute, BasicFormDataPoint>
    > = pickBySelectedKeys(
      trialApplicationState,
      REUSABLE_QUESTION_ATTRIBUTES,
    ) as Partial<Record<TrialApplicationQuestionAttribute, BasicFormDataPoint>>

    // If data already exists in the slug don't set any values as it
    // may overwrite or remove existing data
    if (!isNil(trialApplicationState[slug])) {
      return
    }

    setTrialApplicationState({
      ...trialApplicationState,
      [slug]: reusableData,
    })

    formInLocalStorage.set(slug, reusableData)
  }

  const percent = useRef(0)

  const UNKNOWN_ODDS_OF_ELIGIBILITY = {
    criteriaMet: 0,
    label: 'Unknown',
    percent: 0,
    prevPercent: 0,
  }

  const getOddsOfEligibility = (slug?: string | null) => {
    if (!slug || !trialApplicationState[slug]) {
      return UNKNOWN_ODDS_OF_ELIGIBILITY
    }
    const oddsOfEligibility = calculateOddsOfEligibility({
      trialApplication: trialApplicationState[slug] as FormData,
    })

    const criteriaMet = calculateEligibilityMet({
      trialApplication: trialApplicationState[slug] as FormData,
    })

    if (isUndefined(oddsOfEligibility)) {
      return UNKNOWN_ODDS_OF_ELIGIBILITY
    }

    const prevPercent = percent.current

    percent.current = oddsOfEligibility

    function getEligibilityLabel(percent: number) {
      if (percent < 34) {
        return 'Unknown'
      }

      if (percent < 67) {
        return 'Medium'
      }

      return 'High'
    }

    return {
      criteriaMet,
      label: getEligibilityLabel(percent.current),
      percent: percent.current,
      prevPercent,
    }
  }

  const getCurrentQuestionIndex = (slug: string) =>
    questionIndices.get(slug) ?? TRIAL_APPLICATION_STARTING_QUESTION_INDEX

  const getCurrentEligibilityQuestionIndex = (slug: string) =>
    eligibilityQuestionIndices.get(slug) ??
    TRIAL_APPLICATION_ELIGIBILITY_STARTING_QUESTION_INDEX

  const setCurrentQuestionIndex = ({
    index,
    slug,
  }: {
    index: number
    slug: string
  }) => {
    setQuestionIndices(new Map(questionIndices).set(slug, index))
    // Reset scroll position when moving to a new question
    window.scrollTo(0, 0)
  }

  const setCurrentEligibilityQuestionIndex = ({
    index,
    slug,
  }: {
    index: number
    slug: string
  }) => {
    setEligibilityQuestionIndices(
      new Map(eligibilityQuestionIndices).set(slug, index),
    )
  }

  const getTrialApplicationQuestionnaire = (slug: string) =>
    (trialApplicationState[slug] as FormData) ?? {}

  return (
    <TrialApplicationQuestionnaireContext.Provider
      value={{
        getCurrentEligibilityQuestionIndex,
        getCurrentQuestionIndex,
        getOddsOfEligibility,
        getTrialApplicationQuestionnaire,
        persistTrialApplicationQuestionnaireData,
        prefillReusableValuesInTrialApplication,
        setCurrentEligibilityQuestionIndex,
        setCurrentQuestionIndex,
      }}
    >
      {children}
    </TrialApplicationQuestionnaireContext.Provider>
  )
}

export function useTrialApplication() {
  const context = useContext(TrialApplicationQuestionnaireContext)
  if (context === undefined) {
    throw new Error(
      'useTrialApplication must be used within a TrialApplicationQuestionnaireContext.Provider',
    )
  }
  return context
}

export default TrialApplicationQuestionnaireProvider
