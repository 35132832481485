import { RequestMethod } from '@lib/api/consts'
import internalJsonFetch from '@lib/api/internalJsonFetch'
import ApiRoutes from '@lib/routes/ApiRoutes'
import type { PatientTrialApplicationData } from '@modules/patientTrialApplications/getPatientTrialApplicationDataByIdToken'
import type { ApplyPackageData } from '@modules/patientTrialApplications/persistApplyPackageSubmit/types'

export default function updateApplyPackage({
  patientTrialApplicationIdToken,
  payload,
}: {
  patientTrialApplicationIdToken: string
  payload: ApplyPackageData
}) {
  return internalJsonFetch<PatientTrialApplicationData>({
    authenticated: true,
    body: { data: payload.data, label: payload.label },
    requestMethod: RequestMethod.PUT,
    url: ApiRoutes.v1.patients.trials.applyPackage(
      patientTrialApplicationIdToken,
    ),
  })
}
