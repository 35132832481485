import type { PickerSelectOption } from '@components/primitives/Form/Inputs/FormPickerSelect'
import type { Question } from '@lib/questionnaire'
import type { Geolocation } from '@lib/types/Geolocation'
import calculateDistance from '@lib/utilities/calculateDistance'
import type { TrialLocationForTrialApplyQuestion } from '@modules/patientTrialApplications/helpers/transformTrialLocationForTrialApplyQuestion'
import {
  cityStatecodeString,
  locationToPoint,
} from '@modules/trials/helpers/parseLocationsFromTrial/parseTruncatedLocationsFromTrial'
import { TRIAL_LOCATIONS_MAX_VIEWABLE_COUNT } from './constants'
import type {
  GeneratePrescreeningQuestionConfigProps,
  TrialApplicationQuestionAttribute,
} from './generatePrescreeningQuestionConfig'

export const PickedTrialLocationAttribute: TrialApplicationQuestionAttribute =
  'pickedTrialLocation'
export const NO_TRIAL_LOCATION_VALUE = 'none'
export const MAX_MULTI_SITE_APPLIES = 3

export default function generateNearestTrialLocation({
  currentLocation,
  nearestTrialLocations,
}: Pick<
  GeneratePrescreeningQuestionConfigProps,
  'currentLocation' | 'nearestTrialLocations'
>): Question {
  const pickerOptions = nearestTrialLocations
    .slice(0, TRIAL_LOCATIONS_MAX_VIEWABLE_COUNT) // pick first N nearest locations
    .map(convertTrialLocationToPickerOption(currentLocation))
    .concat({
      icon: 'faTimes',
      subtitle: 'No, I will not travel to these locations',
      value: NO_TRIAL_LOCATION_VALUE,
    })

  return {
    attribute: PickedTrialLocationAttribute,
    description: 'Select the location you are willing to travel to.',
    input: {
      key: 'pickerselect',
      pickerOptions,
      type: 'pickerselect',
    },
    isPrompt: false,
    required: true,
    showIfComplete: true,
    title: 'Are you willing to travel to one of these locations?',
  }
}

// Curry in the `currentLocation` and return a function to do the map
function convertTrialLocationToPickerOption(currentLocation: Geolocation) {
  return (
    trialLocation: TrialLocationForTrialApplyQuestion,
  ): PickerSelectOption => {
    const distanceAway = calculateDistance(
      locationToPoint(trialLocation),
      currentLocation,
    )
    const distanceString = distanceAway
      ? `(~${Math.ceil(Math.ceil(distanceAway) / 10) * 10} miles away)`
      : ''

    return {
      icon: trialLocation.verified ? 'faBadgeCheck' : 'faClinicMedical',
      subtitle: `${cityStatecodeString(trialLocation)} ${distanceString}`,
      title: trialLocation.facility ?? undefined,
      value: trialLocation.id,
    }
  }
}
