export const UNITED_STATES = 'United States'
export const CANADA = 'Canada'
export const AVAILABLE_COUNTRIES = [CANADA, UNITED_STATES]

export function hasAvailableCountry(country: string | null) {
  if (!country) {
    return false
  }

  return AVAILABLE_COUNTRIES.includes(country)
}
