import type { TrialApplicationQuestionAttribute } from '../generatePrescreeningQuestionConfig'

export type EligibilityCriteriaFieldKey = `eligibilityCriteria-${number}`

export const EligibilityCriteriaAttribute: TrialApplicationQuestionAttribute =
  'eligibilityCriteria'

const fieldKeySeparator = '-'

export default function getEligibilityCriteriaFieldKey(
  id: number,
  isInclusion: boolean,
) {
  const inclusionExclusion = isInclusion ? 'inclusion' : 'exclusion'
  return [EligibilityCriteriaAttribute, id, inclusionExclusion].join(
    fieldKeySeparator,
  ) as EligibilityCriteriaFieldKey
}

export function buildEligibilityCriteriaWithFieldKeyFromId(
  id: string | number,
) {
  return [EligibilityCriteriaAttribute, id].join(
    fieldKeySeparator,
  ) as EligibilityCriteriaFieldKey
}

export function getIdFromEligibilityCriteriaFieldKey(fieldKey: string) {
  const id = fieldKey.split(fieldKeySeparator)[1]
  return id ? parseInt(id, 10) : undefined
}

export function getIsInclusionFromEligibilityCriteriaFieldKey(
  fieldKey: string,
) {
  const inclusionExclusion = fieldKey.split(fieldKeySeparator)[2] ?? 'inclusion'
  return inclusionExclusion === 'inclusion'
}

export function extractEligibilityKeysFromData<T>(
  data: T extends {} ? T : never,
) {
  return Object.keys(data).filter((key) =>
    key.startsWith(EligibilityCriteriaAttribute),
  )
}
