import { TherapeuticArea } from '@lib/types/TherapeuticArea'
import { cancerMatcherRegexp } from '@lib/utilities/cancerMatcherRegexp'

// Checks if therapeuticAreas contain Oncology, or if any condition contains a cancer-related term
export function isOncologyTrial({
  conditions,
  therapeuticAreas,
}: {
  conditions?: {
    colloquialTermStripped: string | null
  }[]
  therapeuticAreas: string[]
}) {
  return (
    therapeuticAreas.includes(TherapeuticArea.Oncology) ||
    conditions?.some(
      (condition) =>
        condition.colloquialTermStripped &&
        cancerMatcherRegexp.test(condition.colloquialTermStripped),
    )
  )
}
