import pickBy from 'lodash/pickBy'
import isEmptyString from '../text/isEmptyString'

/**
 * An extension of the `pickBy` function from `lodash` that only picks keys that
 * are present in the `selectedKeys` array.
 */
export default function pickBySelectedKeys<T = object>(
  data: Record<string, unknown>,
  selectedKeys: string[],
): Record<string, T> {
  return pickBy(
    selectedKeys.reduce((obj, key) => ({ ...obj, [key]: data[key] }), {}),
    (v) => v !== undefined && !isEmptyString(v),
  ) as Record<string, T>
}
