import type { ArrayElement } from '@lib/types/arrayElement'
import type { ConditionForTrialApplyQuestion } from '@modules/patientTrialApplications/helpers/transformClinicalTrialConditionForTrialApplyQuestion'
import type { EligibilityCriterionForTrialApplyQuestion } from '@modules/patientTrialApplications/helpers/transformEligibilityCriterionForTrialApplyQuestion'
import type { PatientPrescreeningData } from '@modules/patientTrialApplications/types/PatientTrialApplicationData'
import { isOncologyTrial } from '@modules/trials/helpers/isOncologyTrial'
import type { Question } from '..'
import generateOncologyPrescreeningQuestions from './generateOncologyPrescreeningQuestions'
import type { GeneratePrescreeningQuestionConfigProps } from './generatePrescreeningQuestionConfig'

export const StructuredPrescreeningQuestionAttributes = [
  'geneticMarkers',
  'lineOfTherapy',
  'stageProgression',
  'substageProgression',
] as const

export type StructuredPrescreeningQuestionAttribute = ArrayElement<
  typeof StructuredPrescreeningQuestionAttributes
>

/**
 * Returns a set of structured pre-screening questions based on the colloquial term(s)
 * and therapeutic area(s) of the trial.
 */
export default function generateStructuredPrescreeningQuestions({
  conditions,
  eligibilityCriteria,
  geneticMarkers,
  prescreeningData,
  prescreeningPostSubmitCallback,
  therapeuticAreas,
}: {
  conditions: ConditionForTrialApplyQuestion[]
  eligibilityCriteria: EligibilityCriterionForTrialApplyQuestion[]
  geneticMarkers: string[]
  prescreeningData?: PatientPrescreeningData
  prescreeningPostSubmitCallback: GeneratePrescreeningQuestionConfigProps['prescreeningPostSubmitCallback']
  therapeuticAreas: string[]
}) {
  function wrapWithPrescreeningPostSubmitCallback(
    prescreeningQuestion: Question,
    persist: boolean,
  ) {
    return {
      ...prescreeningQuestion,
      questionSubmitCallbacks: {
        postSubmitCallback: async () => {
          prescreeningPostSubmitCallback &&
            (await prescreeningPostSubmitCallback(persist))
        },
      },
    }
  }

  const prescreeningQuestions: Question[] = []

  const eligibilityCriterionComponents = eligibilityCriteria.flatMap(
    (ec) => ec.criterionComponents,
  )

  if (isOncologyTrial({ conditions, therapeuticAreas })) {
    prescreeningQuestions.push(
      ...generateOncologyPrescreeningQuestions({
        eligibilityCriterionComponents,
        geneticMarkers,
        prescreeningData,
      }),
    )
  }

  // Wrap each question with the post-submit callback
  // The last question should pass in `persist: true` to the callback
  // so that the prescreening data is persisted to the database
  return prescreeningQuestions.map((q, index) =>
    wrapWithPrescreeningPostSubmitCallback(
      q,
      index === prescreeningQuestions.length - 1,
    ),
  )
}
