import type { StructuredEligibilityCriteriaOntologySource } from '@prisma/client'

export enum PowerOntologySubClass {
  FrequencyOfUse = 'frequencyOfUse',
  IsMedicationHelping = 'isMedicationHelping',
  StableDose = 'stableDose',
  WillingToStop = 'willingToStop',
  currentMedicationCount = 'currentMedicationCount',
}

export enum PowerOntologyClass {
  Classic = 'classic',
  Comorbidity = 'comorbidity',
  Condition = 'condition',
  Descriptive = 'descriptive',
  Drug = 'drug',
  DrugCategory = 'drug category',
  Gene = 'gene',
  Gleason = 'gleason',
  Grade = 'grade',
  Ligand = 'ligand',
  Receptor = 'receptor',
  Substance = 'substance',
  TNM = 't_n_m',
}

export enum PowerOntologyVocabulary {
  Admin = 'admin',
  Conditions = 'conditions',
  CurrentDrugs = 'current drugs',
  DiseaseStaging = 'disease stage',
  GeneticReceptors = 'genetic receptor',
  GradeGleasonScore = 'grade gleason score',
  LabResults = 'lab result',
  Other = 'other',
  PreviousDrugs = 'previous drugs',
  Treatments = 'treatments',
}

export const PowerOntologySource: StructuredEligibilityCriteriaOntologySource =
  'PowerHeuristicsV0'

/**
 * Inclusive set needs a marker when there is no comment, or no matches found, given a set type.
 * We use "any" to represent this.
 **/
export const PowerOntologyInclusiveSetAny = 'any'
