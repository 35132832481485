import type { Question } from '@lib/questionnaire'
import type { ArrayElement } from '@lib/types/arrayElement'
import type { EligibilityCriterionForTrialApplyQuestion } from '@modules/patientTrialApplications/helpers/transformEligibilityCriterionForTrialApplyQuestion'
import type { PatientPrescreeningData } from '@modules/patientTrialApplications/types/PatientTrialApplicationData'
import {
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '@modules/trials/helpers/parseEligibilityCriteria/types'
import { PowerHeuristicsV0DataTypeValidator } from '@modules/trials/types/TrialEligibilityCriterionComponent'
import isUndefined from 'lodash/isUndefined'
import type { EligibilityCriterionFromTrialAssociation } from '../eligibilityCriteria/EligibilityCriterionFromTrialAssociation'
import generateGeneticMarkersQuestion from './questions/generateGeneticMarkersQuestion'
import generateLineOfTherapyQuestion from './questions/generateLineOfTherapyQuestion'
import generateStageProgressionQuestions from './questions/generateStageProgressionQuestions'

/**
 * Generate pre-screening questions specific to oncology trials.
 * Questions will vary based on the eligibility criteria components
 * related to the trial.
 */
export default function generateOncologyPrescreeningQuestions({
  eligibilityCriterionComponents,
  geneticMarkers,
  prescreeningData,
}: {
  eligibilityCriterionComponents: EligibilityCriterionForTrialApplyQuestion['criterionComponents']
  geneticMarkers: string[]
  prescreeningData?: PatientPrescreeningData
}): Question[] {
  const oncologyPrescreeningQuestions: Question[] = []

  if (geneticMarkers.length > 0) {
    oncologyPrescreeningQuestions.push(
      generateGeneticMarkersQuestion(geneticMarkers),
    )
  }

  const shouldIncludeLineOfTherapy =
    isUndefined(prescreeningData?.lineOfTherapy) &&
    eligibilityCriterionComponents.some(isLineOfTherapyComponent)

  // What would indicate that we need this question?
  // Should we hard-code an include-list of colloquial terms?
  if (shouldIncludeLineOfTherapy) {
    oncologyPrescreeningQuestions.push(generateLineOfTherapyQuestion())
  }

  oncologyPrescreeningQuestions.push(
    ...generateStageProgressionQuestions(prescreeningData),
  )

  return oncologyPrescreeningQuestions
}

function isLineOfTherapyComponent(
  component: ArrayElement<
    EligibilityCriterionFromTrialAssociation['criterionComponents']
  >,
) {
  const parsedRawDataResult = PowerHeuristicsV0DataTypeValidator.safeParse(
    component.rawData,
  )

  const rawDataContainsLineOfTherapy = parsedRawDataResult.success
    ? Object.keys(parsedRawDataResult.data).includes('line_of_therapy')
    : false

  const { eligibilityCriterionConcept } = component

  const conceptIsTreatmentRelated =
    eligibilityCriterionConcept.ontologyClass === 'include' &&
    eligibilityCriterionConcept.ontologySource === PowerOntologySource &&
    eligibilityCriterionConcept.ontologyVocabulary ===
      PowerOntologyVocabulary.Treatments

  return rawDataContainsLineOfTherapy || conceptIsTreatmentRelated
}
