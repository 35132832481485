const defaultOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  timeZone: 'UTC',
  year: 'numeric',
}

/**
 * Returns a date string in UTC time. Even if a user's browser has a timezone, (like -8:00 pacific time) this display the day/month/year in UTC time.
 */
export const utcDateString = (
  date: Date,
  options: Intl.DateTimeFormatOptions = defaultOptions,
): string => date.toLocaleString('en-US', options)

// Formats like: MM/DD/YYYY
const MMDDYYYYOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  timeZone: 'UTC',
  year: 'numeric',
}
// Formats like October 13, 2023
export const WrittenDateOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  timeZone: 'UTC',
  year: 'numeric',
}
// Formats like October 13
export const ShortWrittenDateOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  timeZone: 'UTC',
}

export const utcDateStringMMDDYYYY = (date: Date): string =>
  utcDateString(date, MMDDYYYYOptions)
