export enum TherapeuticArea {
  Cardiology = 'cardiology',
  Dermatology = 'dermatology',
  Gastroenterology = 'gastroenterology',
  Gynecology = 'gynecology',
  Hematology = 'hematology',
  Immunology = 'immunology',
  InfectiousDiseasesAndVaccines = 'infectious diseases and vaccines',
  MetabolismAndEndocrinology = 'metabolism and endocrinology',
  Neurology = 'neurology',
  Oncology = 'oncology',
  Ophthalmology = 'ophthalmology',
  Orthopedics = 'orthopedics',
  Otolaryngology = 'otolaryngology',
  PainManagement = 'pain management',
  Pediatrics = 'pediatrics',
  Psychiatry = 'psychiatry',
  RareGeneticDiseases = 'rare genetic diseases',
  Respirology = 'respirology',
  Rheumatology = 'rheumatology',
  SubstanceAbuse = 'substance abuse',
  Urology = 'urology',
}
