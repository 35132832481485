import GeolocationProvider from '@components/hooks/GeolocationProvider/index.client'
import PostApplyProvider from '@components/hooks/PostApplyProvider/index.client'
import TrialApplicationQuestionnaireProvider from '@components/hooks/TrialApplicationQuestionnaireProvider'
import { CookiesProvider } from 'react-cookie'
import {
  PageLayoutAndTracking,
  type LayoutProps,
} from './HeaderComponents/PageLayoutAndTracking'

/**
 * Wrapper for `Layout` that includes providers for urlParams, geolocation, cookies and trial application context
 *
 * @param LayoutProps LayoutProps
 */
export function TrialApplicationLayout(props: LayoutProps) {
  return (
    <CookiesProvider>
      <TrialApplicationQuestionnaireProvider>
        <GeolocationProvider>
          <PageLayoutAndTracking {...props} />
        </GeolocationProvider>
      </TrialApplicationQuestionnaireProvider>
    </CookiesProvider>
  )
}

export function PostTrialApplyLayout(props: LayoutProps) {
  return (
    <CookiesProvider>
      <PostApplyProvider>
        <GeolocationProvider>
          <PageLayoutAndTracking {...props} />
        </GeolocationProvider>
      </PostApplyProvider>
    </CookiesProvider>
  )
}
