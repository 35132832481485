// From https://stackoverflow.com/questions/15774555/efficient-regex-for-canadian-postal-code-function
const CA_POSTAL_CODE_REGEX =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
const ZIP_CODE_REGEX = /^\d{5}/ // 5 digits

/* From wikipedia: https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#:~:text=9%20a%20digit%3A-,Format,-Coverage */
const UK_POSTAL_CODE_REGEX = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i

/* From wikipedia: https://en.wikipedia.org/wiki/Postcodes_in_Australia, NZ also uses 4 digits */
const AU_NZ_POSTAL_CODE_REGEX = /^\d{4}$/ // 4 digits

/* From wikipedia: https://en.wikipedia.org/wiki/Postal_codes_in_Israel */
const IL_POSTAL_CODE_REGEX = /^\d{7}$/ // 7 digits

type ValidatedPostalOrZipCode = {
  value: string
} & (
  | {
      country: string
      countryCode: string
      isValid: true
      type: 'postalCode' | 'zipCode'
    }
  | {
      country?: never
      countryCode?: never
      isValid: false
      type?: never
    }
)

type ValidPostalCodeMatcher = {
  country: string
  countryCode: string
  matcher: RegExp
  type: 'postalCode' | 'zipCode'
}

const zipcodeMatchers: ValidPostalCodeMatcher[] = [
  {
    country: 'Israel',
    countryCode: 'IL',
    matcher: IL_POSTAL_CODE_REGEX,
    type: 'postalCode',
  },
  {
    country: 'United States',
    countryCode: 'US',
    matcher: ZIP_CODE_REGEX,
    type: 'zipCode',
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    matcher: CA_POSTAL_CODE_REGEX,
    type: 'postalCode',
  },
  {
    country: 'United Kingdom',
    countryCode: 'UK',
    matcher: UK_POSTAL_CODE_REGEX,
    type: 'postalCode',
  },
  {
    country: 'Australia',
    countryCode: 'AU',
    matcher: AU_NZ_POSTAL_CODE_REGEX,
    type: 'postalCode',
  },
]

export function isValidPostalOrZipCode(
  value: string,
): ValidatedPostalOrZipCode {
  const validZipcodeMatch = zipcodeMatchers.find(({ matcher }) =>
    matcher.test(value.trim()),
  )
  if (validZipcodeMatch) {
    return {
      country: validZipcodeMatch.country,
      countryCode: validZipcodeMatch.countryCode,
      isValid: true,
      type: validZipcodeMatch.type,
      value: value.trim(),
    }
  }

  return {
    isValid: false,
    value: value.trim(),
  }
}
