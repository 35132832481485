import { createConditionSelectOptions } from '@components/app/ConditionSelect'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faIdBadge } from '@fortawesome/pro-regular-svg-icons/faIdBadge'
import { faLocation } from '@fortawesome/pro-regular-svg-icons/faLocation'
import type { Geolocation } from '@lib/types/Geolocation'
import { utcDateString } from '@lib/utilities/date/utcDateString'
import validateEmailFormat from '@lib/utilities/input/validateEmailFormat'
import { isValidPostalOrZipCode } from '@lib/utilities/location/isValidPostalOrZipCode'
import type { CustomConsentForm } from '@modules/patientTrialApplications/customConsentForms/getCustomConsentFormForNctId'
import type { ConditionForTrialApplyQuestion } from '@modules/patientTrialApplications/helpers/transformClinicalTrialConditionForTrialApplyQuestion'
import type { EligibilityCriterionForTrialApplyQuestion } from '@modules/patientTrialApplications/helpers/transformEligibilityCriterionForTrialApplyQuestion'
import type { TrialLocationForTrialApplyQuestion } from '@modules/patientTrialApplications/helpers/transformTrialLocationForTrialApplyQuestion'
import type { PatientPrescreeningData } from '@modules/patientTrialApplications/types/PatientTrialApplicationData'
import { isValidPhoneNumber } from 'libphonenumber-js'
import isUndefined from 'lodash/isUndefined'
import type { Question } from '..'
import { NAME_PLACEHOLDER_VALUE } from './NAME_PLACEHOLDER_VALUE'
import type { EligibilityCriteriaFieldKey } from './eligibilityCriteria/getEligibilityCriteriaFieldKey'
import generateNearestTrialLocation from './generateNearestTrialLocationQuestion'
import generateStructuredPrescreeningQuestions from './generateStructuredPrescreeningQuestions'

export const REUSABLE_QUESTION_ATTRIBUTES: TrialApplicationQuestionAttribute[] =
  [
    'address',
    'biologicalSex',
    'dateOfBirth',
    'email',
    'fullAddress',
    'name',
    'genderIdentity',
    'identity',
    'phoneNumber',
    'zipcode',
  ]

export type TrialApplicationQuestionAttribute =
  | EligibilityCriteriaFieldKey
  | 'address'
  | 'age'
  | 'biologicalSex'
  | 'conditions'
  | 'dateOfBirth'
  | 'eligibilityCriteria'
  | 'email'
  | 'fullAddress'
  | 'genderIdentity'
  | 'identity'
  | 'latitude'
  | 'longitude'
  | 'name'
  | 'phoneNumber'
  | 'pickedTrialLocation'
  | 'zipcode'
  | TrialApplicationDataAskedPostApply

export type TrialApplicationDataAskedPostApply =
  | 'insurance'
  | 'websiteRating'
  | 'doctor'

export type GeneratePrescreeningQuestionConfigProps = {
  addressPostSubmitCallback?: () => Promise<void>
  conditions: ConditionForTrialApplyQuestion[]
  currentLocation: Geolocation
  customConsentForm?: CustomConsentForm
  eligibilityCriteria: EligibilityCriterionForTrialApplyQuestion[]
  geneticMarkers: string[]
  healthyVolunteers?: boolean
  nctId?: string
  nearestTrialLocations: TrialLocationForTrialApplyQuestion[]
  prescreeningData?: PatientPrescreeningData
  prescreeningPostSubmitCallback?: (persist: boolean) => Promise<void>
  shouldSkipZipCode: boolean
  therapeuticAreas: string[]
  zipPostSubmitCallback?: () => Promise<void>
}

export const NO_CONDITIONS_VALUE = 'None'
export function generatePrescreeningQuestionConfig({
  addressPostSubmitCallback,
  conditions,
  currentLocation,
  customConsentForm,
  eligibilityCriteria,
  geneticMarkers,
  healthyVolunteers,
  nctId,
  nearestTrialLocations,
  prescreeningData,
  prescreeningPostSubmitCallback,
  shouldSkipZipCode,
  therapeuticAreas,
  zipPostSubmitCallback,
}: GeneratePrescreeningQuestionConfigProps): Question[] {
  return [
    {
      attribute: 'name',
      description: 'Enter your full name',
      input: {
        autoComplete: 'name',
        icon: faIdBadge,
        id: 'name',
        key: 'name',
        placeholder: NAME_PLACEHOLDER_VALUE,
        type: 'text',
      },
      isPrompt: false,
      required: true,
      title: 'What is your name?',
    },
    {
      attribute: 'dateOfBirth',
      description: 'Select your date of birth',
      input: {
        icon: faCalendar,
        id: 'dateOfBirth',
        key: 'dateOfBirth',
        type: 'datePicker',
      },
      isPrompt: false,
      required: true,
      title: 'When were you born?',
      validation: {
        errorMessage: 'Please use a valid birthday',
        validator: (value: string) =>
          utcDateString(new Date(value)) !== utcDateString(new Date()),
      },
    },
    {
      attribute: 'biologicalSex',
      autoSubmit: true,
      description: 'Select the sex you were assigned at birth',
      input: {
        id: 'biologicalSex',
        items: [
          {
            label: 'Female',
            value: 'Female',
          },
          {
            label: 'Male',
            value: 'Male',
          },
          {
            label: 'Intersex',
            value: 'Intersex',
          },
        ],
        key: 'biologicalSex',
        maxPerRow: [1, 3],
        multiselect: false,
        type: 'genericCheckboxGroup',
      },
      isPrompt: false,
      required: true,
      title: 'What is your biological sex?',
    },
    {
      attribute: 'genderIdentity',
      autoSubmit: true,
      input: {
        id: 'genderIdentity',
        items: [
          {
            label: 'Female',
            value: 'Female',
          },
          {
            label: 'Male',
            value: 'Male',
          },
          {
            label: 'Other',
            value: 'Other',
          },
        ],
        key: 'genderIdentity',
        maxPerRow: [1, 3],
        multiselect: true,
        type: 'genericCheckboxGroup',
      },
      isPrompt: false,
      required: false,
      title: 'Which gender expression do you identify with?',
    },
    {
      attribute: 'identity',
      description:
        'Why we ask: medical research has historically struggled with diversity. Help us change that!',
      input: {
        items: [
          {
            label: 'White',
            value: 'White',
          },
          {
            label: 'Hispanic or Latinx',
            value: 'Hispanic or Latinx',
          },
          {
            label: 'Black or African American',
            value: 'Black or African American',
          },

          {
            label: 'East Asian',
            value: 'East Asian',
          },
          {
            label: 'South Asian',
            value: 'South Asian',
          },
          {
            label: 'American Indian or Alaska Native',
            value: 'American Indian or Alaska Native',
          },
          {
            label: 'Native Hawaiian and Pacific Islander',
            value: 'Native Hawaiian and Pacific Islander',
          },
          {
            label: 'Other',
            value: 'Other',
          },
          {
            label: 'I prefer not to say',
            value: 'Confidential',
          },
        ],
        key: 'identity',
        multiselect: true,
        placeholder: 'Select all that apply',
        type: 'genericCheckboxGroup',
        wrap: true,
      },
      isPrompt: false,
      required: true,
      title: 'What race or ethnicity do you most identify with?',
    },
    {
      attribute: 'email',
      description: 'Enter your email',
      input: {
        autoComplete: 'email',
        icon: faEnvelope,
        id: 'email',
        key: 'email',
        placeholder: 'clint1930@dirtyharry.com',
        type: 'email',
      },
      isPrompt: false,
      required: true,
      title: 'What email can the recruitment coordinator reach you at?',
      validation: {
        errorMessage: 'Please use a valid email',
        validator: (value: string) => validateEmailFormat(value),
      },
    },
    {
      attribute: 'phoneNumber',
      description: 'Enter your phone number',
      input: {
        key: 'phoneNumber',
        type: 'phone',
      },
      isPrompt: false,
      required: true,
      title: 'What phone number can the recruitment coordinator reach you at?',
      validation: {
        errorMessage: 'Must provide a valid phone number',
        validator: (value: string) => isValidPhoneNumber(value),
      },
    },
    {
      attribute: 'address',
      description: 'Search for your address',
      input: {
        icon: faLocation,
        key: 'address',
        type: 'text',
      },
      isPrompt: false,
      questionSubmitCallbacks: {
        postSubmitCallback: async () => {
          addressPostSubmitCallback && addressPostSubmitCallback()
        },
      },
      required: true,
      shouldSkip: !shouldShowFullAddressQuestion(nctId),
      title: 'What is your address?',
    },
    {
      attribute: 'zipcode',
      description: 'Enter your zipcode',
      input: {
        icon: faLocation,
        key: 'zipcode',
        placeholder: '90210',
        type: 'text',
      },
      isPrompt: false,
      questionSubmitCallbacks: {
        postSubmitCallback: async () => {
          zipPostSubmitCallback && zipPostSubmitCallback()
        },
      },
      required: true,
      shouldSkip: shouldSkipZipCode,
      title: 'What is your zipcode?',
      validation: {
        errorMessage: 'Zipcode must be at least four digits',
        validator: (value: string) => isValidPostalOrZipCode(value).isValid,
      },
    },
    {
      attribute: 'conditions',
      description:
        'Why we ask: to provide you with more accurate recommendations of other relevant trials.',
      input: {
        items: [
          ...createConditionSelectOptions(conditions, healthyVolunteers),
          { label: NO_CONDITIONS_VALUE, value: NO_CONDITIONS_VALUE },
        ].flatMap((item) => (item ? [item] : [])),
        key: 'conditions',
        maxPerRow: [1, 1],
        multiselect: true,
        placeholder: 'Select all that apply',
        type: 'genericCheckboxGroup',
      },
      isPrompt: false,
      required: true,
      shouldSkip: healthyVolunteers && conditions.length === 0,
      // We should always ask about conditions because the choices can vary between trials
      showIfComplete: true,

      title: 'Which conditions do you have?',
    },
    ...generateStructuredPrescreeningQuestions({
      conditions,
      eligibilityCriteria,
      geneticMarkers,
      prescreeningData,
      prescreeningPostSubmitCallback,
      therapeuticAreas,
    }),
    generateNearestTrialLocation({
      currentLocation,
      nearestTrialLocations,
    }),
    {
      attribute: 'gaveCustomConsent',
      autoSubmit: true,
      description: customConsentForm?.subTitle,
      hideEligibilityScore: true,
      hideNextButton: true,
      input: {
        id: nctId,
        key: 'customConsent',
        type: 'customConsent',
      },
      isPrompt: false,
      required: true,
      shouldSkip: !customConsentForm,
      title: customConsentForm?.title ?? '',
    },
  ]
}

function shouldShowFullAddressQuestion(nctId?: string): boolean {
  const ADDRESS_APPLICABLE_NCT_IDS = ['NCT05113771']

  return !isUndefined(nctId) && ADDRESS_APPLICABLE_NCT_IDS.includes(nctId)
}
