import properCase from '@lib/utilities/text/properCase'
import type { ConditionForTrialApplyQuestion } from '@modules/patientTrialApplications/helpers/transformClinicalTrialConditionForTrialApplyQuestion'
import uniqBy from 'lodash/uniqBy'

export const createConditionSelectOptions = (
  conditions: ConditionForTrialApplyQuestion[],
  healthyVolunteers = false,
) => {
  // We want to display preferred terms if possible, but have them linked to the condition name
  const conditionOptions = uniqBy(
    conditions.map((condition) => {
      return {
        label: properCase(condition.colloquialTermStripped ?? condition.name),
        value: condition.name,
      }
    }),
    'label',
  )

  // Fallback if we don't have preferred terms for conditions
  if (conditionOptions.length === 0) {
    return conditions.map((condition) => {
      return { label: condition.name, value: condition.name }
    })
  }

  if (healthyVolunteers) {
    conditionOptions.push({
      label: 'Healthy Volunteer',
      value: 'Healthy Volunteer',
    })
  }

  return conditionOptions
}
