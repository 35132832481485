import CheckValue from '@components/primitives/ToggleButton/EligibilityToggleGroup/EligibilityToggleValue'
import {
  EligibilityCriteriaAttribute,
  getIsInclusionFromEligibilityCriteriaFieldKey,
} from '@lib/questionnaire/trialApplication/eligibilityCriteria/getEligibilityCriteriaFieldKey'
import { NO_TRIAL_LOCATION_VALUE } from '@lib/questionnaire/trialApplication/generateNearestTrialLocationQuestion'
import type { FormData } from '@lib/types/FormData'
import { NO_CONDITIONS_VALUE } from './generatePrescreeningQuestionConfig'

export default function calculateOddsOfEligibility({
  trialApplication,
}: {
  trialApplication: FormData
}) {
  // If the user is not willing to travel to a nearby location the odds of eligibility are automatically low
  if (trialApplication['pickedTrialLocation'] === NO_TRIAL_LOCATION_VALUE) {
    return 0
  }

  // If the user is not willing to give custom consent, they are not eligible
  if (trialApplication['gaveCustomConsent'] === 'false') {
    return 0
  }

  const eligibilityCriteria = Object.entries(trialApplication ?? {}).filter(
    ([key, _value]) => key.includes(EligibilityCriteriaAttribute),
  )

  const positiveCriteria = eligibilityCriteria.filter(([key, value]) => {
    const isInclusion = getIsInclusionFromEligibilityCriteriaFieldKey(key)
    if (isInclusion) {
      return value === CheckValue.YES || value === CheckValue.UNSURE
    } else {
      return value === CheckValue.NO
    }
  })
  const eligibleConditionCount =
    (trialApplication['conditions'] as string[] | undefined)?.filter(
      (c) => c !== NO_CONDITIONS_VALUE,
    ).length ?? 0 > 0
      ? 1
      : 0

  return Math.round(
    ((positiveCriteria.length + eligibleConditionCount) /
      (eligibilityCriteria.length + 1)) *
      100,
  )
}
