import type { Geolocation } from '@lib/types/Geolocation'

type CalculateDistanceOptions = {
  units: 'miles' | 'kilometers'
}

const NAIVE_EARTH_RADIUS_METERS = 6378137
export const METERS_TO_MILES = 0.000621371192
export const MILES_TO_METERS = 1609.344

function toRadian(value: number): number {
  return (Math.PI * value) / 180.0
}

function squared(value: number): number {
  return value * value
}

function haversine(value: number): number {
  return squared(Math.sin(value / 2.0))
}

/**
 * Calculate distance between two points "as the crow flies" using a modified Haversine.
 * See https://en.wikipedia.org/wiki/Haversine_formula
 *
 * @param a Point A: Latitude and Longitude
 * @param b Point B: Latitude and Longitude
 * @param options.units "kilometers" or "miles", defaults to "miles"
 */
export default function calculateDistance(
  a: Geolocation,
  b: Geolocation,
  { units }: CalculateDistanceOptions = { units: 'miles' },
): number | undefined {
  if (!a.latitude || !b.latitude || !a.longitude || !b.longitude) {
    return
  }
  if (a.latitude === b.latitude && a.longitude === b.longitude) {
    return 0
  }

  const aLatRad = toRadian(a.latitude)
  const bLatRad = toRadian(b.latitude)
  const aLngRad = toRadian(a.longitude)
  const bLngRad = toRadian(b.longitude)

  const theta =
    haversine(bLatRad - aLatRad) +
    Math.cos(aLatRad) * Math.cos(bLatRad) * haversine(bLngRad - aLngRad)
  const distanceInMeters =
    2 * NAIVE_EARTH_RADIUS_METERS * Math.asin(Math.sqrt(theta))

  if (units === 'kilometers') {
    return distanceInMeters / 1000.0
  }

  return distanceInMeters * METERS_TO_MILES
}
