import CheckValue from '@components/primitives/ToggleButton/EligibilityToggleGroup/EligibilityToggleValue'
import {
  EligibilityCriteriaAttribute,
  getIsInclusionFromEligibilityCriteriaFieldKey,
} from '@lib/questionnaire/trialApplication/eligibilityCriteria/getEligibilityCriteriaFieldKey'
import type { FormData } from '@lib/types/FormData'

export default function calculateEligibilityMet({
  trialApplication,
}: {
  trialApplication: FormData
}) {
  const eligibilityCriteria = Object.entries(trialApplication ?? {}).filter(
    ([key, _value]) => key.includes(EligibilityCriteriaAttribute),
  )

  const positiveCriteria = eligibilityCriteria.filter(([key, value]) => {
    const isInclusion = getIsInclusionFromEligibilityCriteriaFieldKey(key)
    if (isInclusion) {
      return value === CheckValue.YES
    } else {
      return value === CheckValue.NO
    }
  })

  return positiveCriteria.length
}
