import {
  array,
  literal,
  number,
  object,
  record,
  string,
  union,
  type TypeOf,
} from 'zod'

const PowerHeuristicsV0ConditionsDataTypeValidator = object({
  name: string(),
  status: string().or(array(string())),
  type: string(),
})

// Conditions and co-morbidities have few variations, but status can be an array for ECOG etc
export type PowerHeuristicsV0ConditionsData = TypeOf<
  typeof PowerHeuristicsV0ConditionsDataTypeValidator
>

export const PowerHeuristicsV0DiseaseStagingDataTypeValidator = object({
  cancer_type: string().optional(),
  feature: string().optional(),
  phase: string().optional(),
  status: string(),
  type: string(),
  value: union([
    string(),
    array(string()),
    object({
      m: array(string()),
      n: array(string()),
      t: array(string()),
    }),
  ]).optional(),
})

// Far fewer variations here, but the value fields are very `type` dependent
export type PowerHeuristicsV0DiseaseStagingData = TypeOf<
  typeof PowerHeuristicsV0DiseaseStagingDataTypeValidator
>

export const PowerHeuristicsV0GeneticReceptorDataTypeValidator = object({
  method: string().optional(),
  name: string(),
  progression: string().optional(),
  sample: string().optional(),
  status: string(),
  treated: string().optional(),
  type: literal('gene').or(literal('ligand')).or(literal('receptor')),
  value: string(),
})

// There are 51 or so keys, this is just a useful sample; all have `type` though
export type PowerHeuristicsV0GeneticReceptorData = TypeOf<
  typeof PowerHeuristicsV0GeneticReceptorDataTypeValidator
>

export const PowerHeuristicsV0GradeGleasonScoreDataTypeValidator = object({
  status: literal('include').or(literal('exclude')),
  type: literal('gleason').or(literal('grade')),
  value: array(string()),
})

export type PowerHeuristicsV0GradeGleasonScoreData = TypeOf<
  typeof PowerHeuristicsV0GradeGleasonScoreDataTypeValidator
>

// TO DO - should refactor our entity extraction pipeline and have specific validator at prompt level
// see lib/nlp/clients/openai/getAssertiveQuestionSummaryForEligibilityCriterion.prompt.ts as example
export const PowerHeuristicsV0TreatmentsDataTypeValidator = object({
  line_of_therapy: array(string()),
  name: string(),
  priorTreatment: string(),
  status: string(),
  type: string(),
})

// GPT-4 results have more consistency in the return type, the `status` has some variation in content
export type PowerHeuristicsV0TreatmentsData = TypeOf<
  typeof PowerHeuristicsV0TreatmentsDataTypeValidator
>

export const ConceptDataTypeValidator = object({
  name: string(),
  ontologyClass: string().optional(),
  ontologyCode: string().optional(),
  ontologyIdentifier: string(),
  ontologySource: literal('Athena')
    .or(literal('GoogleCloudHealthNlp'))
    .or(literal('PowerHeuristicsV0'))
    .or(literal('UMLS'))
    .or(literal('ValxD')),
  ontologyVocabulary: string(),
})

export type ConceptData = TypeOf<typeof ConceptDataTypeValidator>

export const TrialEligibilityCriterionComponentRawDataTypeValidator = record(
  string(),
  string()
    .or(number())
    .or(array(string().or(number()))),
)

export type PowerHeuristicsV0Data =
  | PowerHeuristicsV0ConditionsData
  | PowerHeuristicsV0DiseaseStagingData
  | PowerHeuristicsV0GeneticReceptorData
  | PowerHeuristicsV0GradeGleasonScoreData
  | PowerHeuristicsV0TreatmentsData

export const PowerHeuristicsV0DataTypeValidator = union([
  PowerHeuristicsV0ConditionsDataTypeValidator,
  PowerHeuristicsV0DiseaseStagingDataTypeValidator,
  PowerHeuristicsV0GeneticReceptorDataTypeValidator,
  PowerHeuristicsV0GradeGleasonScoreDataTypeValidator,
  PowerHeuristicsV0TreatmentsDataTypeValidator,
])
